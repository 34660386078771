import { useState } from 'react'
import uniqid from 'uniqid'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import { carousels } from '../../portfolio'
import './Portfolio.css'

const Portfolio = () => {
  if (!carousels.length) return null;

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === carousels.length - 1 ? 0 : slide + 1);
  }

  const prevSlide = () => {
    setSlide(slide === 0 ? carousels.length - 1 : slide - 1);
  }

  return (
    <section id='carousel' className='section carousel'>
      <h2 className='section__title'>Portfolio</h2>

      <div className='carousel__grid'>
        <ArrowBackIosRoundedIcon className='arrow prev' onClick={prevSlide} />
        <div className="carousel__slide">
          {carousels.map((carousel, idx) => (
            <div key={uniqid()} className={slide === idx ? "carousel__item" : "carousel__item hidden"}>
              <img src={carousel.src} alt={carousel.caption} />
              <p>{carousel.caption}</p>
            </div>
          ))}
        </div>
        <ArrowForwardIosRoundedIcon className='arrow next' onClick={nextSlide} />

        <span className='indicators'>
          {carousels.map((_, idx) => (
            <button aria-label='Carousel Indicator' className={slide === idx ? 'indicator' : 'indicator inactive'} type='button' key={uniqid()} onClick={() => setSlide(idx)} />
          ))}
        </span>
      </div>
    </section>
  )
}

export default Portfolio
