const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://gitlab.com/chalidabu/cleanfolio',
  title: 'JS.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Chalid Abu Bakar',
  role: 'Front End Engineer',
  description:
    "Hey, I'm Abu. A Jakarta based Front-End Developer who specialises in crafting responsive and visually appealing web applications. I thrive in collaborative environments, enjoy solving complex problems, and continuously seek opportunities to learn and grow in the ever-evolving tech landscape.",
  resume: 'https://drive.google.com/file/d/1bZTVXmGCgSwCyNfUY1VoGuHXwOq9YGhj/view?usp=sharing',
  portfolio: 'https://docs.google.com/presentation/d/1XmCRdQOENYhJ_QA8nnpBuYHajTNGM1g4P3cFEf6eG64/edit?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/chalidabu/',
    github: 'https://gitlab.com/chalidabu/',
  },
}

const carousels = [
  {
    id: 1,
    src: '/img/SiapHalal.png',
    caption: 'SiapHalal'
  },
  {
    id: 2,
    src: "/img/mpp.png",
    caption: "Man Power Planning App"
  },
  {
    id: 3,
    src: "/img/royalcanin.png",
    caption: "Royal Canin"
  },
  {
    id: 4,
    src: "/img/guesehat.jpg",
    caption: "Guesehat"
  },
  {
    id: 5,
    src: "/img/creatorfest.png",
    caption: "Creator Fest"
  },
  {
    id: 6,
    src: "/img/yuknyanyi.png",
    caption: "Yuk Nyanyi"
  },
  {
    id: 7,
    src: "/img/listerine.jpg",
    caption: "Listerine"
  },
  {
    id: 8,
    src: "/img/xteam.png",
    caption: "Permata X-Team"
  },
  {
    id: 9,
    src: "/img/permata.png",
    caption: "Permata Mobile X"
  },
  {
    id: 10,
    src: "/img/xl.jpg",
    caption: "XL"
  },
  {
    id: 11,
    src: "/img/mia.jpg",
    caption: "MIA"
  },
  {
    id: 12,
    src: "/img/future-trend.png",
    caption: "Future Trend in Healthcare"
  }
]

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'SiapHalal',
    description:
      'Empowering F&B with Effortless Halal Solutions',
    stack: ['SASS', 'Javascript', 'React'],
    sourceCode: 'https://gitlab.com/chalidabu/siaphalal',
    livePreview: 'https://siaphalal.com/',
  },
  {
    name: 'Manpower Planning App',
    description:
      'A digital tool that streamlines recruitment for team leaders by simplifying new hire requests and managing them online, removing the need for in-person meetings.',
    stack: ['SASS', 'Javascript', 'React'],
  },
  {
    name: 'Guesehat',
    description:
      'A health platform providing articles, tools, and directories to help users manage health, find services like clinics or gyms, and connect with doctors.',
    stack: ['SASS', 'JQuery', 'HTML'],
    livePreview: 'https://www.guesehat.com/',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Next',
  'SASS',
  'Tailwind',
  'Bootstrap',
  'Git',
  'Webpack',
  'NPM',
  'Google Analytics',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'chalidabu@mail.com',
}

export { header, about, carousels, projects, skills, contact }
